<template>
  <v-row justify="center">
    <v-dialog
      v-model="showDialog"
      max-width="500"
      transition="dialog-top-transition"
      persistent
    >
      <v-card>
        <v-card-text class="text-h5">
          <br>
          <div style="margin-top: 10px">{{errorText}}</div>
        </v-card-text>
        <v-spacer></v-spacer>
        <v-card-actions class="justify-end">
          <v-btn color="grey" text @click="close">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
export default {
  name: "alert",
  data() {
    return {
      errorText: '',
      showDialog: false,
    }
  },
  props: {
    text: {
      type: String,
      default: '',
    }
  },
  watch: {
    text(newText, ) {
      if (this.errorText) {
        if (!newText) {
          // the error is gone, clean the dialog
          this.errorText = '';
          this.showDialog = false;
        }
        return; // show first raised error
      }
      this.errorText = newText;
      this.showDialog = true;
    }
  },
  emits: ['accepted'],
  methods: {
    close() {
      this.showDialog = false;
      this.errorText = '';
      this.$emit('accepted');
    }
  }
}
</script>

<style scoped>

</style>