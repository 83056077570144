<template>
  <v-container>
    <div id="share" v-if="call">
      <div class="container">
        <video class="sharePreview" ref="video" :src-object.prop.camel="stream" autoplay muted playsinline>
        <!--    #TODO fix: no preview on some iOS devices  -->
        </video>
        <v-row justify="center">
          <v-card class="justify-center preview-works-not-everywhere-card">
              <v-card-title class="justify-center"><v-icon size="4vw">mdi-eye-off</v-icon></v-card-title>
              <v-card-text class="text" style="font-size: 2vh">
                Sharing preview may not work in some browsers or on some platforms.
              </v-card-text>
          </v-card>
        </v-row>
      </div>

      <br />
      <v-toolbar id="shareToolbar" color="#ffffffcc" rounded style="z-index: 3">
        <v-toolbar-items>
          <!-- doesn't work normally on FF & Safari due to poor realisation of RTCIceCandidatePairStats -->
          <ConnectionStatus v-if="browser !== 'firefox' && browser !== 'safari'" :peer-call="call"/>
          <!-- Nickname and Hand-Raised feature are available from protocol version 3 -->
          <v-text-field
              v-if="mirrorVersion >= 3"
              class="toolbarText"
              v-model="nickname"
              @keyup.enter="updateNickname"
              @change="updateNickname"
              :label="$t('global.nickname')"
              outlined
              style="min-width: 100px"
          ></v-text-field>
          <div v-if="mirrorVersion >= 3" class="mx-4"></div>
          <v-btn v-if="mirrorVersion >= 3" :color="handRaised ? 'success' : null" @click="toggleHandRaised">
            <v-icon>mdi-hand-back-right</v-icon>
          </v-btn>
          <v-btn @click="requestFullscreen" color="primary">
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
          <v-btn @click="callEnd" color="warning">
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
          <v-btn v-if="intent === intentions.camera && browser !== 'safari'" @click="switchCameras" color="secondary">
            <v-icon>mdi-camera-flip</v-icon>
         </v-btn>
        </v-toolbar-items>
      </v-toolbar>
    </div>
    <alert :text="alertText" @accepted="makeCall"/>
  </v-container>
</template>

<script>
import alert from "@/components/alert.vue";
import ConnectionStatus from "@/components/ConnectionStatus.vue";
import {mirrorVersion, sendCommandToPeer, streamToPeer} from "@/lib/connection";
import {BROWSER, fireResetNeededEvent} from "@/lib/common";

export default {
  name: "Stream",
  components: {
    alert,
    ConnectionStatus,
  },
  data() {
    return {
      mirrorVersion: 0,
      handRaised: false,
      nickname: null,
      call: null,
      stream: null,
      currentFacing: 'environment',
      browser: BROWSER,
      alertText: '',
    }
  },
  emits: ["call-started"],
  methods: {
    makeCallTransparentInvocationHandler() {
      // some browsers strongly require sharing features (e.g. getDisplayMedia) to be called by user gesture.
      // so, we create a dialog with a button, pressing which will call a sharing function
      this.alertText = this.$t('lobby.press-ok-to-start');
    },
    async makeCall() {
      const stream = await this.getStream(this.getDefaultDisplayMediaOptions());
      if (!stream) { // User probably did not allow sharing
        this.reset();
        return;
      }
      this.changeStream(stream);
      this.mirrorVersion = mirrorVersion;
      this.call = streamToPeer(this.stream);
      this.call.on("error", console.error);
      this.$emit('call-started');
    },
    async getStream(displayMediaOptions) {
      let stream;
      const streamFabric = this.intent === this.intentions.camera ? navigator.mediaDevices.getUserMedia : navigator.mediaDevices.getDisplayMedia;
      try {
        stream = await streamFabric.call(navigator.mediaDevices, displayMediaOptions);
      } catch (err) {
        console.warn("get Media failed:\n" + err);
        return null;
      }
      return stream;
    },
    getDefaultDisplayMediaOptions() {
      return {
        selfBrowserSurface: "exclude", // Exclude share.thinhoc.com tab from selection
        video: {
          cursor: "always",
          width: {ideal: 3840},
          height: {ideal: 2060},
          framerate: {ideal: 30, max: 30},
        },
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100
        }
      };
    },
    async switchCameras() {
      let options = this.getDefaultDisplayMediaOptions();
      if (this.currentFacing === 'environment') {
        this.currentFacing = 'user';
      } else {
        this.currentFacing = 'environment';
      }
      options.video.facingMode = this.currentFacing;
      const stream = await this.getStream(options);
      this.changeStream(stream);
      let senders = this.call.peerConnection.getSenders();
      let streamTracks = this.stream.getTracks();
      senders[0].replaceTrack(streamTracks[0]);
      senders[1].replaceTrack(streamTracks[1]);
    },
    callEnd() {
      sendCommandToPeer("bye", null);
      this.reset();
    },
    closeStream() {
      if (this.stream) this.stream.getTracks().forEach((track) => track.stop());
    },
    changeStream(stream) {
      this.closeStream();
      this.stream = stream;
      this.stream.getTracks().forEach((track) => (track.onended = this.reset));
    },
    reset() {
      fireResetNeededEvent();
    },
    toggleHandRaised() {
      this.handRaised = !this.handRaised;
      sendCommandToPeer('setHandRaised', {state: this.handRaised})
    },
    requestFullscreen() {
      sendCommandToPeer("requestFullscreen", null);
    },
    updateNickname() {
      sessionStorage.setItem('nickname', this.nickname);
    },
  },
  mounted() {
    console.log(`platform is ${navigator.platform}`);
    let makeCallFunc = this.makeCall;
    if (this.browser === 'firefox' || this.browser === 'safari') {
      makeCallFunc = this.makeCallTransparentInvocationHandler;
    }
    window.addEventListener('connection-established', makeCallFunc);
  },
  props: {
    intentions: {
      type: Object,
      default: () => {},
    },
    intent: {
      type: String,
      default: 'mirroring',
    },
  },
}
</script>

<style scoped>
.sharePreview {
  position: relative;
  z-index: 2;
  border-radius: 16px;
  top: 5vh;  
  width: 100%;
  height: 80vh;
}

#shareToolbar {
  position: absolute;
  top: 95%;
  left: 50%;
  transform: translateX(-50%);
}

.preview-works-not-everywhere-card {
  position: absolute;
  top: 0;
  margin-top: 30vh;
  width: 20vw;
  height: fit-content;
  min-width: 250px;
  background-color: #ffffff00;
  color: #ffffffcc;
}
</style>