<template>
  <v-main style="transform: translate(-10%, 10%)" >
    <v-btn icon @click="toggleStats"><v-icon :color="icon.color">{{ icon.type }}</v-icon></v-btn>
    <v-dialog v-model="peerStatsShow">
      <v-card style="max-width: 250px; margin: auto">
        <v-card-title>Streaming Statistics</v-card-title>
        <v-card-text>
          <p>
            <b>Conn. Type:</b> {{ peerConnectionState.peerStats.remoteType || 'unknown' }}<br/>
            <b>Latency:</b> {{ peerConnectionState.peerStats.latency * 1000 }} ms<br/>
            <b>Avail. Bandwidth:</b> {{ (peerConnectionState.peerStats.bandwidth / 1024 / 1024).toFixed(2) }} MBit/s
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<script>
import {peerConnectionState} from '@/lib/connection';

export default {
  data() {
    return {
      peerStatsShow: false,
      peerConnectionState: peerConnectionState,
    }
  },
  props:{
    peerCall: {
      default: null,
    }
  },
  computed: {
    icon() {
      const icon = {
        color: 'warning',
        type: 'mdi-help-circle-outline',
      }
      if (this.peerConnectionState.peerStats.remoteType === 'host') {
        icon.type = 'mdi-lan-connect';
      } else if (this.peerConnectionState.peerStats.remoteType === 'srflx' || this.peerConnectionState.peerStats.remoteType === 'prflx') {
        icon.type = 'mdi-wan';
      } else if (this.peerConnectionState.peerStats.remoteType === 'relay') {
        icon.type = 'mdi-server-network';
      } else {
        icon.type = 'mdi-network-strength-outline';
      }
      if (this.peerConnectionState.peerStats.latency > 0.100 || this.peerConnectionState.peerStats.bandwidth < 2 * 1024 * 1024 /* 2 MBit/s */) {
        icon.color = 'error';
      } else if (this.peerConnectionState.peerStats.latency > 0.025 || this.peerConnectionState.peerStats.bandwidth < 5 * 1024 * 1024 /* 10 MBit/s */) {
        icon.color = 'warning';
      } else {
        icon.color = 'success';
      }
      return icon;
    }
  },
  methods: {
    toggleStats() {
      this.peerStatsShow = !this.peerStatsShow;
    },
  },
}
</script>