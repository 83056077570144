<template>
  <v-app id="app">
    <v-main>
      <v-container>
        <Stream :intent="intent" :intentions="intentions" @call-started="onCallStart"/>
        <div id="lobby" v-if="!inCall">
          <img alt="ThinHoc logo" id="logo" src="./assets/logo.png"
            :class="{ 'anim-stretch': peerConnectionState.connectionStep !== 'IDLE' }" />
          <h2 style="color: white" v-if="peerConnectionState.connectionStep !== 'IDLE'"><br /><br />{{  $t(`connection-step.${peerConnectionState.connectionStep}`) }}</h2>
          <div style="padding-top: 60px">
            <v-card color="#ffffffcc" style="padding: 16px">
              <v-row justify="center">
                <v-col>
                  <h1>ThinHoc Share</h1>
                  <span v-if="!browserSupported" v-html="$t('lobby.unsupported-browser')"></span>
                </v-col>
              </v-row>
              <v-row justify="center" style="padding-top: 16px">
                <v-col>
                  <h2>
                    <v-icon large>mdi-numeric-1-box</v-icon> {{ $t('lobby.intent.title') }}
                  </h2>
                  <h3 class="flex-center">
                    <v-radio-group row v-model="intent" @change="intentChanged">
                      <v-radio :value="intentions.mirror" :disabled="!mirrorAvailable">
                        <template v-slot:label>
                          <div v-if="mirrorAvailable">
                            <v-icon large>mdi-cast</v-icon> {{ $t('lobby.intent.mirror') }}
                          </div>
                          <div v-else>
                            <v-icon large>mdi-cast</v-icon> {{ $t('lobby.intent.mirror-na') }}
                          </div>
                        </template>
                      </v-radio>
                      <v-radio :value="intentions.camera" :disabled="!cameraAvailable">
                        <template v-slot:label>
                          <div v-if="cameraAvailable">
                            <v-icon large>mdi-camera</v-icon> {{ $t('lobby.intent.camera') }} <v-chip style="transform: translateY(-50%)" x-small>beta</v-chip>
                          </div>
                          <div v-else>
                            <v-icon large>mdi-camera</v-icon> {{ $t('lobby.intent.camera-na') }}
                          </div>
                        </template>
                      </v-radio>
                      <v-radio :value="intentions.whiteboard">
                        <template v-slot:label>
                          <div>
                            <v-icon large>mdi-human-male-board-poll</v-icon>
                            {{ $t('lobby.intent.whiteboard') }}
                          </div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </h3>
                </v-col>
              </v-row>
              <h2 style="padding-top: 32px">
                <v-icon large>mdi-numeric-2-box</v-icon> {{ $t('lobby.conn-method.title') }}
              </h2>
              <v-row justify="center" style="padding-top: 16px">
                <v-col v-show="$vuetify.breakpoint.smAndUp">
                  <v-select
                      v-model="localConnectionState.peerId"
                      @focus="localConnectionState.update"
                      @change="localConnectionState.connect"
                      :loading="localConnectionState.loading"
                      :items="localConnectionState.neighbors"
                      item-text="name"
                      item-value="peer_id"
                      solo
                      :label="$t('lobby.conn-method.auto-connect')"
                      prepend-inner-icon="mdi-broadcast"
                  />
                </v-col>
                <v-col v-show="$vuetify.breakpoint.smAndUp">
                  <h2>{{ $t('global.or').toUpperCase() }}</h2>
                </v-col>
                <v-col align="center">
                  <v-text-field
                      style="width: 80%; margin-left: 32px; margin-right: 32px;"
                      @keyup="formatOTC"
                      @keyup.enter="connect"
                      :rules="[rulesOTC.required, rulesOTC.counter]"
                      :error-messages="idFieldErrors"
                      solo
                      required
                      :placeholder="$t('lobby.conn-method.enter-otc')"
                      prepend-inner-icon="mdi-form-textbox-password"
                      v-model="peerOTC"
                  />
                  <v-btn :disabled="peerConnectionState.connectionStep !== 'IDLE'" x-large @click="connect">
                    {{ $t('lobby.connect') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </div>

        <div class="light x1"></div>
        <div class="light x2"></div>
        <div class="light x3"></div>
        <div class="light x4"></div>
        <div class="light x5"></div>
        <div class="light x6"></div>
        <div class="light x7"></div>
        <div class="light x8"></div>
        <div class="light x9"></div>
      </v-container>
    </v-main>
    <alert :text="error" @accepted="reset"/>
  </v-app>
</template>

<script>
import alert from '@/components/alert.vue';
import Stream from "@/components/Stream.vue";

import {browserSupported} from "@/lib/common";
import {
  connectOTC,
  localConnectionState,
  peerConnectionState,
  sendCommandToPeer
} from '@/lib/connection';

export default {
  name: "App",
  components: {
    alert,
    Stream,
  },
  data() {
    return {
      browserSupported: browserSupported,
      intentions: {
        mirror: "mirroring",
        whiteboard: "wbo",
        camera: "camera",
      },
      inCall: false,
      intent: sessionStorage.getItem("last-intent") || "mirroring",
      mirrorAvailable: false,
      cameraAvailable: false,
      peerOTC: sessionStorage.getItem("last-otc") || "",
      rulesOTC: {
        required: (value) => !!value || "Required.",
        counter: (value) =>
          value.replaceAll(" ", "").length <= 8 ||
          this.$t('errors.invalid-otc-length8'),
      },
      peerConnectionState: peerConnectionState,
      localConnectionState: localConnectionState,
    };
  },
  computed: {
    idFieldErrors() {
      if (this.peerConnectionState.error && this.peerConnectionState.error.includes('otc')) {
        return [this.$t(`errors.${this.peerConnectionState.error}`)];
      }
      return [];
    },
    error() {
      if (this.peerConnectionState.error && !this.peerConnectionState.error.includes('otc')) {
        return this.$t(`errors.${this.peerConnectionState.error}`);
      }
      return null;
    },
  },
  methods: {
    connectOTC,
    sendCommandToPeer,
    async connect() {
      await this.connectOTC(this.peerOTC);
    },
    formatOTC() {
      let peerOTC = this.peerOTC.toUpperCase();
      //Replace non-hex
      peerOTC = peerOTC.replace(/[^A-F0-9 ]/, "");

      //Cap length to eight
      var rawLength = peerOTC.replaceAll(" ", "").length;
      if (rawLength > 8) {
        peerOTC = peerOTC.replaceAll(" ", "");
        peerOTC = peerOTC.slice(0, 9);
        rawLength = 8;
      }
      //Format in pairs of two
      if (rawLength % 2 == 0) {
        //Format with spaces
        var formatted = "";
        peerOTC = peerOTC.replaceAll(" ", "");
        for (let i = 0; i < rawLength; i += 2) {
          formatted += peerOTC.slice(i, i + 2) + " ";
        }
        peerOTC = formatted.slice(0, formatted.length - 1); //Remove trailing whitespace
      }
      this.peerOTC = peerOTC;
    },
    intentChanged() {
      sessionStorage.setItem('last-intent', this.intent)
    },
    onCallStart() {
      this.inCall = true;
      window.addEventListener("beforeunload", this._beforeUnload);
    },
    reset() {
      //Clear event as reset has been requested by application
      window.removeEventListener("beforeunload", this._beforeUnload);
      //Reload page to make sure all resources are reset
      location.reload();
    },
    _beforeUnload(ev) {
      if (!confirm(this.$t('errors.confirm-leave').toString())) {
        ev.returnValue = true;
        ev.preventDefault();
      }
    },
  },
  mounted() {
    //Check if mirroring is available
    this.mirrorAvailable = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
    this.cameraAvailable = navigator.mediaDevices && navigator.mediaDevices.getUserMedia;
    let availableFeatures = [
        this.mirrorAvailable ? this.intentions.mirror : null, this.cameraAvailable ? this.intentions.camera : null, this.intentions.whiteboard
    ].filter(feature => feature);
    if (!(this.intent in availableFeatures)) {
      this.intent = availableFeatures[0];
    }
    window.addEventListener('reset-needed', this.reset);
    window.addEventListener(
        'connection-opened',
        () => {
          console.log("Connection opened");
          if (this.intent === this.intentions.mirror || this.intent === this.intentions.camera) {
            this.sendCommandToPeer("requestShare", null);
            this.peerConnectionState.connectionStep = 'requesting-access';
          }
          if (this.intent === this.intentions.whiteboard) {
            this.sendCommandToPeer("requestWhiteboardID", null);
            this.peerConnectionState.connectionStep = 'requesting-whiteboard-id';
          }
        }
    )
  },
};
</script>

<style>
.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toolbarText .v-input__slot {
  transform: translateY(10%)
}

#logo {
  filter: brightness(0) invert(1);
  height: 20vh;
  max-height: 10vw;
}

.anim-stretch {
  animation: stretch 2s linear infinite;
}

@keyframes stretch {
  0% {
    transform: scale(1);
  }

  10% {
    transform: scale(1.1);
  }

  20% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.shadow {
  -webkit-filter: drop-shadow(0px 0px 5px #ffffff88);
  filter: drop-shadow(0px 0px 5px #ffffff88);
}

.share-action {
  margin: 8px;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* Background styles */
  height: 100vh;
  background: radial-gradient(circle closest-corner at center 175px, #555, black 40%) no-repeat;
}

/* Icon margin fix */
.v-input__icon {
  margin-right: 8px !important;
}

.v-input .error--text {
  color: red !important;
}

body {
  -webkit-overflow-y: hidden;
  -moz-overflow-y: hidden;
  -o-overflow-y: hidden;
  overflow-y: hidden;
}

.light {
  z-index: 1;
  position: absolute;
  width: 0px;
  background-color: white;
  box-shadow: #e9f1f1 0px 0px 20px 2px;
  opacity: 0;
  top: 100vh;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
}

.x1 {
  -webkit-animation: floatUp 4s infinite linear;
  -moz-animation: floatUp 4s infinite linear;
  -o-animation: floatUp 4s infinite linear;
  animation: floatUp 4s infinite linear;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}

.x2 {
  -webkit-animation: floatUp 7s infinite linear;
  -moz-animation: floatUp 7s infinite linear;
  -o-animation: floatUp 7s infinite linear;
  animation: floatUp 7s infinite linear;
  -webkit-transform: scale(1.6);
  -moz-transform: scale(1.6);
  -o-transform: scale(1.6);
  transform: scale(1.6);
  left: 15%;
}

.x3 {
  -webkit-animation: floatUp 2.5s infinite linear;
  -moz-animation: floatUp 2.5s infinite linear;
  -o-animation: floatUp 2.5s infinite linear;
  animation: floatUp 2.5s infinite linear;
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
  -o-transform: scale(0.5);
  transform: scale(0.5);
  left: -15%;
}

.x4 {
  -webkit-animation: floatUp 4.5s infinite linear;
  -moz-animation: floatUp 4.5s infinite linear;
  -o-animation: floatUp 4.5s infinite linear;
  animation: floatUp 4.5s infinite linear;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  left: -34%;
}

.x5 {
  -webkit-animation: floatUp 8s infinite linear;
  -moz-animation: floatUp 8s infinite linear;
  -o-animation: floatUp 8s infinite linear;
  animation: floatUp 8s infinite linear;
  -webkit-transform: scale(2.2);
  -moz-transform: scale(2.2);
  -o-transform: scale(2.2);
  transform: scale(2.2);
  left: -57%;
}

.x6 {
  -webkit-animation: floatUp 3s infinite linear;
  -moz-animation: floatUp 3s infinite linear;
  -o-animation: floatUp 3s infinite linear;
  animation: floatUp 3s infinite linear;
  -webkit-transform: scale(0.8);
  -moz-transform: scale(0.8);
  -o-transform: scale(0.8);
  transform: scale(0.8);
  left: -81%;
}

.x7 {
  -webkit-animation: floatUp 5.3s infinite linear;
  -moz-animation: floatUp 5.3s infinite linear;
  -o-animation: floatUp 5.3s infinite linear;
  animation: floatUp 5.3s infinite linear;
  -webkit-transform: scale(3.2);
  -moz-transform: scale(3.2);
  -o-transform: scale(3.2);
  transform: scale(3.2);
  left: 37%;
}

.x8 {
  -webkit-animation: floatUp 4.7s infinite linear;
  -moz-animation: floatUp 4.7s infinite linear;
  -o-animation: floatUp 4.7s infinite linear;
  animation: floatUp 4.7s infinite linear;
  -webkit-transform: scale(1.7);
  -moz-transform: scale(1.7);
  -o-transform: scale(1.7);
  transform: scale(1.7);
  left: 62%;
}

.x9 {
  -webkit-animation: floatUp 4.1s infinite linear;
  -moz-animation: floatUp 4.1s infinite linear;
  -o-animation: floatUp 4.1s infinite linear;
  animation: floatUp 4.1s infinite linear;
  -webkit-transform: scale(0.9);
  -moz-transform: scale(0.9);
  -o-transform: scale(0.9);
  transform: scale(0.9);
  left: 85%;
}

@-webkit-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: 0.8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

@-moz-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: 0.8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

@-o-keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: 0.8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}

@keyframes floatUp {
  0% {
    top: 100vh;
    opacity: 0;
  }

  25% {
    opacity: 1;
  }

  50% {
    top: 0vh;
    opacity: 0.8;
  }

  75% {
    opacity: 1;
  }

  100% {
    top: -100vh;
    opacity: 0;
  }
}
</style>
