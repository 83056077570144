import { Peer } from "peerjs";
import axios from "axios";
import {BROWSER, fireResetNeededEvent} from "@/lib/common";

const MIRROR_PROTOCOL_VERSION_MIN = 2;

const DEFAULT_ICE_SERVERS = {
  iceServers: [{
    urls: [ "stun:fr-turn1.xirsys.com" ]
  }]
}

let CACHED_ICE_SERVERS = undefined;

export let peerConnectionState = {
  connectionStep: "IDLE",
  otc: Math.floor(Math.random() * (16777215 - 1118481) + 1118481).toString(16).toUpperCase(),
  peer: null,
  peerConnection: null,
  peerEstablished: false,
  peerAlive: false,
  error: null,
  peerStats: {
    bandwidth: null,
    latency: null,
    remoteType: null,
    isLocal: false,
  },
  _peerCall: null,
}
export let mirrorVersion = 0;

let _connectionTimeout = null;
let _versionInterrogationTimeout = null;

/**
 *
 * @param command {String}
 * @param data {Object | Null}
 */
export function sendCommandToPeer(command, data) {
  if (!peerConnectionState.peerConnection) {
    return;
  }
  if (!data) {
    peerConnectionState.peerConnection.send({ cmd: command });
  } else {
    peerConnectionState.peerConnection.send(Object.assign({ cmd: command }, data));
  }
}

export function streamToPeer(stream) {
  const peerID = peerConnectionState.peerConnection.peer;
  peerConnectionState._peerCall = peerConnectionState.peer.call(peerID, stream);
  peerConnectionState._peerCall.on("error", console.error);
  return peerConnectionState._peerCall;
}


export async function connectOTC(peerOTC) {
    sessionStorage.setItem("last-otc", peerOTC);
    peerOTC = peerOTC.replaceAll(" ", "");
    peerConnectionState.connectionStep = 'connecting-signalling';
    //Determine correct signalling server & connect
    const server = await getServer(peerOTC);
    if (!server) {  // No suitable server found
        peerConnectionState.connectionStep = 'IDLE';
        return;
    }
    await connectPJS(server);
    await connect(`thoc-otc-${peerOTC}`);
}

async function getServer(peerOTC) {
  if (peerOTC.length === 6) {
    //Legacy peerID; use public PJS server
    return {}; //Standard options
  } else if (peerOTC.length === 8) {
    const serverID = peerOTC.slice(0, 2).toLowerCase();
    const SERVERS = await getServerConfigs();
    if (SERVERS[serverID]) {
      return SERVERS[serverID];
    } else {
      peerConnectionState.error = 'invalid-otc-server-code';
      return null;
    }
  } else {
    peerConnectionState.error = 'invalid-otc-length';
    return null;
  }
}

async function getServerConfigs() {
  let ice_servers = DEFAULT_ICE_SERVERS;

  if (!CACHED_ICE_SERVERS) {
    try {
      CACHED_ICE_SERVERS = (await axios.get('https://api-beta.thinhoc.com/v1/p2p-helper/ice-servers')).data;
    } catch(e) {
      console.warn(`Failed to get ICE Servers from API: ${e}`);
    }
  }
  if (CACHED_ICE_SERVERS) {
    ice_servers = CACHED_ICE_SERVERS
  }
  return {
    a0: {
      host: "pjs-a0.ucytech.net",
      secure: true,
      config: {
        ...ice_servers,
      },
    },
    a1: {
      host: "pjs-a1.ucytech.net",
      secure: true,
      config: {
        ...ice_servers,
      },
    },
    a2: {
      host: "pjs-a2.ucytech.net",
      secure: true,
      config: {
        ...ice_servers,
      },
    },
  }
}

async function connectPJS(server) {
  console.log(server);
  //Init peer.js
  peerConnectionState.connectionStep = 'connecting-signalling';
  return new Promise((resolve) => {
    const peerID = "thoc-share-" + peerConnectionState.otc;
    console.log(`Peer ID: ${peerID} connecting to signalling server ${JSON.stringify(server)}`);
    peerConnectionState.peer = new Peer(peerID, { ...server, pingInterval: 1000 });
    console.log(peerConnectionState.peer);

    peerConnectionState.peer.on("open", function (id) {
      console.debug("PeerJS signaling connection established: " + id);
      peerConnectionState.peerEstablished = true;
      resolve();
    });

    peerConnectionState.peer.on("error", () => {
      peerConnectionState.connectionStep = 'connection-error';
      peerConnectionState.error = 'signalling-server-failed';
    });
  });
}

async function connect(peerID) {
  if (!peerConnectionState.peer) {
    console.error("BUG: Connection to signalling server has not been established!");
    return;
  }
  console.log(`Connecting to peer "${peerID}"`);
  peerConnectionState.peerConnection = peerConnectionState.peer.connect(peerID);
  _initPeerConnection();
}

function _initPeerConnection() {
  peerConnectionState.peerConnection._lastPing = Date.now() / 1000;
  peerConnectionState.peerAlive = true;

  _connectionTimeout = setTimeout(
      () => {
        peerConnectionState.error = 'failed-to-connect';
      },
      7500
  );

  peerConnectionState.peerConnection.on("error", (err) => {
    console.warn(err);
    switch (err.type) {
      case "peer-unavailable":
        peerConnectionState.error = 'failed-to-connect';
        break;
      default:
        break;
    }
  });

  peerConnectionState.peerConnection.on("open", () => {
    //Reset connection timeout
    if (_connectionTimeout) {
      clearTimeout(_connectionTimeout);
      _connectionTimeout = null;
    }
    /* Ping Loop */
    peerConnectionState.peerConnection._pingInterval = setInterval(
        () => {
          if (peerConnectionState.peerConnection._lastPing < Date.now() / 1000 - 5) {
            //Three seconds timeout
            if (peerConnectionState.peerAlive) {
              console.warn("Peer is not alive!");
              //Trigger reset
              peerConnectionState.error = 'ping-timeout';
            }
            peerConnectionState.peerAlive = false;
          } else {
            if (!peerConnectionState.peerAlive) {
              console.warn("Peer is alive again!");
              if (peerConnectionState.error === 'ping-timeout') peerConnectionState.error = '';
            }
            peerConnectionState.peerAlive = true;
          }
          peerConnectionState.peerConnection.send({ cmd: "ping" });
          //Set nickname if already set
          if (sessionStorage.getItem("nickname")) {
            updateNickname();
          }
        },
        1000
    );

    //Request remote version and start Timeout
    peerConnectionState.peerConnection.send({ cmd: "version" });
    _versionInterrogationTimeout = setTimeout(
        () => {
          _versionUnsupported({ error: "timeout" });
        },
      1000
    );
    connectionOpened();
  });

  peerConnectionState.peerConnection.on("data", async (data) => {
    try {
      console.debug(`Received command "${data.cmd}"`);
      switch (data.cmd) {
        case "ping":
          await checkConnectionStats();
          peerConnectionState.peerConnection._lastPing = Date.now() / 1000;
          return;
        case "version":
          if (data.error || data.response < MIRROR_PROTOCOL_VERSION_MIN) {
            _versionUnsupported();
            break;
          }
          mirrorVersion = data.response;
          console.log('new mirror version')
          console.log(mirrorVersion)
          //Reset timeout
          console.info(`Remote running version "${data.response}"`);
          if (_versionInterrogationTimeout) {
            clearTimeout(_versionInterrogationTimeout);
          }
          break;
        case "bye":
          reset();
          return;
        case "requestShare":
          if (data.response) {
            connectionEstablished();
            peerConnectionState.connectionStep = 'starting-share';
          } else {
            peerConnectionState.connectionStep ='access-denied';
            peerConnectionState.error = 'connection-request-rejected';
          }
          break;
        case "requestWhiteboardID":
          if (data.response) {
            peerConnectionState.connectionStep = 'opening-whiteboard';
            if (BROWSER === 'safari' || BROWSER === 'firefox') {
              //Safari & Firefox do not allow window.open in async environments; therefore we open it in current tab
              window.location.href = `https://wbo.thinhoc.com/boards/${data.response}`;
            } else {
              // All other browsers
              window.open(`https://wbo.thinhoc.com/boards/${data.response}`, "_blank");
              reset();
            }
          }
          if (data.error) {
            peerConnectionState.connectionStep = 'request-failed';
            peerConnectionState.error = 'whiteboard-failed';
          }
          break;
      }
      if (!data.response && !data.error) {
        //Unsupported command
        console.info(`Received unsupported command ${data.cmd}`);
        peerConnectionState.peerConnection.send({
          cmd: data.cmd,
          error: "unsupported",
        });
      }
    } catch (e) {
      console.warn(`Failed to process message from peer "${peerConnectionState.peerConnection.peer}":\n${JSON.stringify(data)}`);
      console.debug(e)
    }
  });

  peerConnectionState.peerConnection.on("close", () => {
    console.debug(`Connection to "${peerConnectionState.peerConnection.peer}" closed by peer`);
    reset();
  });
}

function _versionUnsupported(data) {
  //Unsupported version -> Prompt to update ThinHoc
  console.error(`Reported version "${data.response || data.error}"; required version >= "${MIRROR_PROTOCOL_VERSION_MIN}"`);
  peerConnectionState.error = 'remote-not-up-to-date';
}
function updateNickname() {
  peerConnectionState.peerConnection.send({ cmd: 'setNickname', nickname: sessionStorage.getItem('nickname') })
}

function reset() {
  fireResetNeededEvent();
}

function connectionOpened() {
  let event = new Event('connection-opened');
  window.dispatchEvent(event);
}

function connectionEstablished() {
  let event = new Event('connection-established');
  window.dispatchEvent(event);
}

async function checkConnectionStats() {
  if (!peerConnectionState._peerCall) {
    return;
  }
  // Called every PING
  const rtcDC = peerConnectionState.peerConnection.peerConnection; // We need the internal RTCPeerConnection and not the peer.js representation
  const rtcCall = peerConnectionState._peerCall.peerConnection;
  if (rtcDC.sctp.transport.iceTransport) {
    const pair = rtcDC.sctp.transport.iceTransport.getSelectedCandidatePair();
    peerConnectionState.peerStats.remoteType = pair.remote.type;
    peerConnectionState.peerStats.isLocal = (pair.remote.type === 'host');
  }
  const stats = await rtcCall.getStats();
  stats.forEach((report) => {
    console.debug(report)
    if (report.type === 'candidate-pair' && report.nominated && report.availableOutgoingBitrate > 0) {
      peerConnectionState.peerStats.bandwidth = report.availableOutgoingBitrate || 0;
      peerConnectionState.peerStats.latency = report.currentRoundTripTime;
    }
  });
}









export let localConnectionState = {
  neighbors: [],
  loading: true,
  peerId: null,
  update: ipkvUpdate,
  connect: ipkvConnect,
}


async function ipkvConnect() {
  peerConnectionState.connectionStep = 'auto-connect';

  const SERVERS = await getServerConfigs();
  await connectPJS(SERVERS["a0"]); //Connect to machine signalling server

  await connect(localConnectionState.peerId);
}

async function ipkvInit() {
  localConnectionState.loading = true;
  let ifrm = document.createElement("iframe");
  ifrm.setAttribute("src", `https://mdnsadv-thoc-share-${peerConnectionState.otc}.local/`);
  ifrm.style.display = 'none';
  ifrm.style.width = "0px";
  ifrm.style.height = "0px";
  document.body.appendChild(ifrm);
  setTimeout(ipkvUpdate, 2500);
}

async function ipkvUpdate() {
  localConnectionState.loading = true;
  localConnectionState.neighbors = (await axios.get(`https://api-beta.thinhoc.com/v1/p2p-helper/share-otc/${peerConnectionState.otc.toLowerCase()}`)).data;
  localConnectionState.loading = false;
}

ipkvInit();
